import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { navigate } from 'gatsby';
import DashboardLayout from '../../components/layouts/DashboardLayout';
import CardsList from '../../components/Common/CardsList';
import MinifiedProperty from '../../components/Properties/MiniProperty';
import Pagination from '../../components/Common/Pagination';
import { toast } from 'react-toastify';
import { Row, Button } from 'reactstrap';
import { FaSearch } from '@react-icons/all-files/fa/FaSearch';
import { FaTrashAlt } from '@react-icons/all-files/fa/FaTrashAlt';
import { useBreakpoint } from '@a1-ict/gatsby/utils/breakpoint';
import PrivateRoute from '../../components/Security/privateRoute';

import { fetchFavoriteProperties, deleteFavoriteProperty } from '@a1-ict/fsbo/userSlice';
import { selectFavoriteProperties } from '@a1-ict/fsbo/userSlice/selectors';
import { settings } from '../../settings';

const ActionButtons = ({ property }) => {
  const breakpoints = useBreakpoint();
  const dispatch = useDispatch();

  const deleteFavorite = async () => {
    const response = await dispatch(
      deleteFavoriteProperty({ dispatch, settings, property_id: property.id })
    );

    if (response.payload && response.payload.ok)
      dispatch(fetchFavoriteProperties({ dispatch, settings, queryParams: {} }));
    else
      toast.error('Грешка при запазването на данните', {
        position: toast.POSITION.BOTTOM_CENTER,
      });
  };

  return (
    <div className={`d-flex ${breakpoints.xs && 'flex-column justify-content-between'}`}>
      <button
        className={`btn  ${!breakpoints.xs ? 'mr-2 animated' : 'p-1 '}  `}
        onClick={() => navigate(`/property/${property.id}`)}
      >
        <span className="d-flex align-items-center">
          <FaSearch className={`${!breakpoints.md && 'mr-2'}`}></FaSearch>
          {!breakpoints.md && 'Виж'}
        </span>
      </button>
      <Button
        outline={true}
        color={!breakpoints.xs ? 'secondary' : ''}
        className={`d-flex align-items-center ${breakpoints.xs && ' p-1 border-0 bg-transparent'}`}
        onClick={deleteFavorite}
      >
        <FaTrashAlt className={`${!breakpoints.md && 'mr-2'}`}></FaTrashAlt>
        {!breakpoints.md && 'Изтрий'}
      </Button>
    </div>
  );
};

const Favourites = () => {
  const breakpoints = useBreakpoint();
  const dispatch = useDispatch();
  const { items, total_pages } = useSelector(selectFavoriteProperties);

  useEffect(() => {
    dispatch(fetchFavoriteProperties({ dispatch, settings, queryParams: {} }));
  }, []);

  const handlePageClick = (data) => {
    dispatch(
      fetchFavoriteProperties({
        dispatch,
        settings,
        queryParams: { CurrentPage: data.selected + 1 },
      })
    );
  };

  const columns = [
    {
      label: null,
      content: (row) => (
        <MinifiedProperty
          color="black"
          renderDetails={true}
          property={row}
          extraClass="mr-2"
        ></MinifiedProperty>
      ),
    },
    {
      label: null,
      wrapperClass: breakpoints.xs ? 'align-self-stretch d-flex' : '',
      content: (row) => <ActionButtons property={row}></ActionButtons>,
    },
  ];

  return (
    <PrivateRoute>
      <DashboardLayout title="Любими имоти">
        <Row>
          <div className="flex-grow-1">
            {items.length > 0 ? (
              <CardsList columns={columns} data={items}></CardsList>
            ) : (
              <h6>Все още нямате добавени любими имоти</h6>
            )}
            <div className="pt-2  d-flex justify-content-center align-items-center ">
              {total_pages > 1 && (
                <Pagination
                  handlePageClick={handlePageClick}
                  pageCount={total_pages}
                  forcePage={0}
                />
              )}
            </div>
          </div>
        </Row>
      </DashboardLayout>
    </PrivateRoute>
  );
};

export default Favourites;
